import { useState } from "react";

import { ReactComponent as PersonIcon } from "./assets/images/person.svg";
import { ReactComponent as CompanyIcon } from "./assets/images/company.svg";
import { ReactComponent as AllariamasLogo } from "./assets/images/logo.svg";

import styles from "./App.module.css";

const AccountTooltip = ({ type, show, position }) => {
  const accountTypeInfo = {
    person: {
      title: "¿Qué es una cuenta personal?",
      description:
        "Relación de dependencia, Jubilado o Pensionado, Responsable monotributo, Responsable inscripto o Autónomo y Desempleado",
    },
    company: {
      title: "¿Qué es una cuenta empresa?",
      description:
        "Sociedad Anónima (SA), Sociedad de Responsabilidad Limitada (SRL), Sociedad de Hecho (SH), Sociedades del Capítulo I –Sección IV (CAP I SECC IV), Sociedades Simples y Sociedades por Acciones Simplificadas (SAS).",
    },
  };
  return (
    <div
      className={`${styles.tooltip} ${
        position === "left" && styles.tooltipLeft
      } ${position === "right" && styles.tooltipRight} ${
        show ? "" : styles.hide
      }`}
    >
      <h4>
        <b>{type && accountTypeInfo[type].title}</b>
      </h4>
      <p>{type && accountTypeInfo[type].description}</p>
    </div>
  );
};

const App = (props) => {
  const [showTooltip, setShowTooltip] = useState([false, false]);
  const [selectedAccountType, setSelectedAccountType] = useState("company");

  const accountTypeInfo = {
    person: {
      title: "¿Qué es una cuenta personal?",
      description:
        "Relación de dependencia, Jubilado o Pensionado, Responsable monotributo, Responsable inscripto o Autónomo y Desempleado",
    },
    company: {
      title: "¿Qué es una cuenta empresa?",
      description:
        "Sociedad Anónima (SA), Sociedad de Responsabilidad Limitada (SRL), Sociedad de Hecho (SH), Sociedades del Capítulo I –Sección IV (CAP I SECC IV), Sociedades Simples y Sociedades por Acciones Simplificadas (SAS).",
    },
  };

  const handleContinue = () => {
    if (selectedAccountType === "person")
      window.location.href = `${process.env.REACT_APP_ABRIR_CUENTA_PF}`;
    else window.location.href = `${process.env.REACT_APP_ABRIR_CUENTA_PJ}`;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftColumn}>
        <h2>¡Bienvenido! </h2>
        <h1>Seleccioná el tipo de cuenta que querés abrir.</h1>
        <div className={styles.cardWrapper}>
          <div
            className={`${styles.card} ${
              selectedAccountType || styles.initial
            } ${
              selectedAccountType !== null
                ? selectedAccountType === "person"
                  ? styles.selected
                  : styles.notSelected
                : ""
            }`}
            onMouseEnter={() => setShowTooltip([true, false])}
            onMouseLeave={() => setShowTooltip([false, false])}
            onClick={() => setSelectedAccountType("person")}
          >
            <PersonIcon />
            <h3>Cuenta personal</h3>
          </div>
          <div
            className={`${styles.card} ${
              selectedAccountType || styles.initial
            } ${
              selectedAccountType !== null
                ? selectedAccountType === "company"
                  ? styles.selected
                  : styles.notSelected
                : ""
            }`}
            onMouseOver={() => setShowTooltip([false, true])}
            onMouseLeave={() => setShowTooltip([false, false])}
            onClick={() => setSelectedAccountType("company")}
          >
            <CompanyIcon />
            <h3>Cuenta empresa</h3>
          </div>
          <AccountTooltip
            type="person"
            show={showTooltip[0]}
            position="right"
          />
          <AccountTooltip
            type="company"
            show={showTooltip[1]}
            position="left"
          />
        </div>
        <div
          className={`mb-5 ${!selectedAccountType && styles.hide} ${
            styles.accountTypeInfo
          }`}
        >
          <p>
            <b>
              {selectedAccountType &&
                accountTypeInfo[selectedAccountType].title}
            </b>
          </p>
          <p>
            {selectedAccountType &&
              accountTypeInfo[selectedAccountType].description}
          </p>
        </div>
        <button
          className={styles.button}
          onClick={handleContinue}
          disabled={!selectedAccountType}
        >
          Continuar
        </button>
        <div className={styles.footerText}>
          <p>
            ¿Ya tenés cuenta en Allaria+?
            <a
              href="https://app.allariamas.com.ar"
              rel="noopener noreferrer"
              target="_blank"
            >
              Inicia sesión
            </a>
          </p>
        </div>
      </div>
      <AllariamasLogo className={styles.logo} />
    </div>
  );
};

export default App;
